<template>
    <div class="AboutUs">
        <!-- <bread-link></bread-link> -->
        <div class="banner1">
            <div class="title-box">
                <div class="ch-text">在线留言</div>
            </div>
        </div>
        <div class="warpper">
            <div class="form-box">
                <div class="title">在线留言</div>
                <a-form-model class="form" ref="form" :model="form" :selfUpdate="true">
                    <a-form-model-item label="留言主题" :label-col="formLayout.labelCol" :wrapper-col="formLayout.wrapperCol" prop="title" :rules="{ required: true, message: '请输入留言主题' }">
                        <a-input v-model="form.title" placeholder="请输入留言主题" />
                    </a-form-model-item>
                    <a-form-model-item label="留言内容" :label-col="formLayout.labelCol" :wrapper-col="formLayout.wrapperCol" prop="content" :rules="{ required: true, message: '请输入留言内容' }">
                        <a-textarea v-model="form.content" placeholder="请输入留言内容" :rows="5" />
                    </a-form-model-item>
                    <a-form-model-item label="您的姓名" :label-col="formLayout.labelCol" :wrapper-col="formLayout.wrapperCol" prop="name">
                        <a-input v-model="form.name" placeholder="请输入您的姓名" />
                    </a-form-model-item>
                    <a-form-model-item label="电子邮箱" :label-col="formLayout.labelCol" :wrapper-col="formLayout.wrapperCol" prop="email">
                        <a-input v-model="form.email" placeholder="请输入电子邮箱" />
                    </a-form-model-item>
                    <a-form-model-item label="联系地址" :label-col="formLayout.labelCol" :wrapper-col="formLayout.wrapperCol" prop="address">
                        <a-input v-model="form.address" placeholder="请输入联系地址" />
                    </a-form-model-item>
                </a-form-model>
                <div class="btns">
                    <a-button class="submit" type="primary" size="large" @click="submit">提交</a-button>
                    <a-button class="reset" size="large" @click="reset"> 重置</a-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { createGwOnlineMessage } from '@/api/basic'

const formLayout = {
    labelCol: { xs: 0, sm: 0, md: 0, lg: 4, xl: 4, xxl: 4 },
    wrapperCol: { xs: 24, sm: 24, md: 24, lg: 20, xl: 20, xxl: 20 }
}

export default {
    name: 'Industry',
    data() {
        return {
            formLayout,
            form: {}
        }
    },
    mounted() {
    },
    methods: {
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    let params = this.form
                    createGwOnlineMessage(params)
                        .then(() => {
                            this.countDown()
                            this.form = {}
                        })
                        .catch(error => {
                            this.$message.error('留言失败，' + error.message)
                        })
                } else {
                    return false
                }
            })
        },
        reset() {},
        countDown() {
            let secondsToGo = 5
            const modal = this.$success({
                title: '留言成功，感谢您的支持！我们收到会将第一时间回复您！',
                content: `窗口将在 ${secondsToGo} 秒后关闭`
            })
            const interval = setInterval(() => {
                secondsToGo -= 1
                modal.update({
                    content: `窗口将在 ${secondsToGo} 秒后关闭`
                })
            }, 1000)
            setTimeout(() => {
                clearInterval(interval)
                modal.destroy()
            }, secondsToGo * 1000)
        }
    }
}
</script>
<style lang="less">
.ant-input {
    border: none;
    background: #f5f5f5;
    border-radius: 3px;
}
.has-error .ant-input {
    background: #f5f5f5;
    border-radius: 3px;
    border: 1px solid #ff4d4f;
}
.has-error .ant-input:not([disabled]):hover {
    background: #f5f5f5;
}
</style>
<style lang="less" scoped>
.AboutUs {
    background: #f5f5f5;
    .banner1 {
        width: 100%;
        height: 680px;
        background: url(../../../assets/images/bg55.png) 50% 50% no-repeat;
        position: relative;
        .title-box {
            width: 1300px;
            text-align: center;
            position: absolute;
            top: 20%;
            left: 50%;
            transform: translate(-50%, 50%);
            color: #fff;
            .en-text {
                font-size: 60px;
            }
            .ch-text {
                font-size: 50px;
                margin-top: 40px;
                position: relative;
                padding-bottom: 20px;
                position: relative;
                font-weight: bold;
            }
            .ch-text:before {
                content: '';
                width: 40px;
                height: 5px;
                background: #fff;
                position: absolute;
                bottom: 0;
                left: 50%;
                margin-left: -20px;
            }
        }
    }
    .warpper {
        background: #f5f5f5;
        padding: 80px 0;
        .form-box {
            width: 800px;
            background: #ffffff;
            margin: 0 auto;
            padding: 30px 60px;
            .title {
                width: 100%;
                height: 70px;
                line-height: 70px;
                border-bottom: 1px solid #d4d4d4;
                font-size: 28px;
                font-weight: bold;
                color: #333333;
                text-align: center;
            }
            .form {
                margin-top: 40px;
            }
        }
        .btns {
            text-align: center;
            margin-top: 50px;
            .submit {
                margin-right: 20px;
                font-size: 14px;
            }
            .reset {
                background: #f5f5f5;
                border-color: #f5f5f5;
                font-size: 14px;
            }
        }
    }
}
</style>
